@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply font-body text-paragraph leading-6 bg-gray-200;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white font-primary;
  }
  .section {
    @apply py-12 lg:py-32;
  }
  .section-title {
    @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
  }
  .subtitle {
    @apply text-base text-paragraph mb-16 lg:mb-24 max-w-[520px];
  }
  .btn {
    @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm hover:bg-accent-hover transition-all;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
  .input {
    @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
  }
  .textarea {
    @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
  }
  .active {
    @apply text-accent;
  }
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #000;
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #42465a transparent #42465a transparent;
  border-radius: 50%;
  animation: spin-anim 0.8s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}